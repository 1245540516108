* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
	color: #23201f;
	min-height: 100vh;
}

h1,
h2,
.fnt-united {
	font-family: UnitedSans, sans-serif;
}

h1 {
	font-size: 1.5rem;
	line-height: 1.2;
}

@media (min-width: 768px) {
	h1 {
		font-size: 2rem;
	}
}

a,
a:active,
a:visited {
	color: inherit;
	text-decoration: none;
}

a:hover {
	color: #1a4d96;
	text-decoration: none;
}

p {
	line-height: 1.5;
}

img {
	max-width: 100%;
}

ul.no-bullets {
    list-style: none;
	margin: 0 0 2rem;
}

.bold {
    font-weight: bold;
}

.no-nothing {
    list-style: none;
    padding: 0;
}

.btn {
	background-image: none;
	border: none;
	cursor: pointer;
	margin: 0;
	text-decoration: none;
	touch-action: manipulation;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	-webkit-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-ms-flex-item-align: start;
	-webkit-align-self: flex-start;
	-webkit-flex-item-align: flex-start;
	align-self: flex-start;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
	box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
	-moz-border-radius: 3px;
	-o-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	height: 46px;
	line-height: 46px;
	padding-left: 22px;
	padding-right: 22px;
}

.btn-sm {
	font-size: 14px;
	height: 36px;
	line-height: 36px;
	padding-left: 18px;
	padding-right: 18px;
}

.btn:hover {
	-webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 35%);
	box-shadow: 0 3px 6px rgb(0 0 0 / 35%);
}

.btn--primary {
	background-color: #1c539c;
	color: #fff;
}

.btn--primary:active,
.btn--primary:focus,
.btn--primary:hover,
.btn--primary:visited {
	background-color: #004e97;
	color: #fff;
}

.red,
.clr-red {
	color: red;
}

.centered {
	text-align: center;
}

.text-right {
	text-align: right;
}

.d-none {
	display: none;
}

@media (min-width: 768px) {
	.d-md-none {
		display: none;
	}
	.d-md-block {
		display: block;
	}
}


.m-0 {
	margin: 0;
}